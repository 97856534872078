import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import dev from 'assets/illustrations/lemon.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="Graphic of a Lemon" />
        </Thumbnail>
        <Details theme={theme}>
          <h3>About me</h3>
          <p>
            You're probably wondering what's with the lemons on this page right? Well that's my dog- He is called
            Lemonade. A Golden Retriever. He works alongside me on all my above projects.
            <br />
            <br />
            When I am not working I am usually exploring the Peak District with Lemonade or travelling with my friends.
            <br />
            <br />I am also a lover of Games. Little bit of trivia - Game Development was something I was thinking of
            exploring before I started in Web Development.
          </p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
