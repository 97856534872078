export const work = [
  {
    id: 23,
    title: 'Missguided',
    url: 'https://apps.apple.com/gb/app/missguided-womens-clothing/id842503500',
    description:
      'Using React Native I worked with a team of developers on the new Missguided iOS and Android app. Building features like Sort, Filter and Search.',
    tools: ['React Native', 'TypeScript', 'Redux Saga', 'GraphQL'],
  },

  {
    id: 0,
    title: 'The Wild Agency',
    url: 'http://thewild.agency/',
    description: `The Wild is a social first creative agency. I created a site that shows off their creative work and team. `,
    tools: ['Gatsby JS', 'React', 'GraphQL'],
  },
  {
    id: 1,
    title: 'VT',
    url: 'https://vt.co/',
    description: `VT is a news and entertainment platform providing content from around the world. Their content reaches millions of people every day.`,
    tools: ['Next JS', 'Node', 'Redux'],
  },
  {
    id: 2,
    title: 'Ortho Clinical Diagnostics',
    url: 'https://www.orthoclinicaldiagnostics.com/',
    description: `Ortho Clinical Diagnostics is an in vitro diagnostics company that makes products and diagnostic equipment for blood testing.`,
    tools: ['Drupal', 'PHP'],
  },
  {
    id: 3,
    title: 'Twitter Polarity',
    url: 'https://github.com/Vickerslewis/twitter-polarity',
    description: `Twitter Polarity is a Node project I created which lets you search Twitter for keywords and return their polarity rate.`,
    tools: ['Node', 'Twitter API'],
  },
  {
    id: 4,
    title: 'Pomegrante Media',
    url: 'https://www.pomegranate.co.uk/',
    description: `Pomegranate Media is a London-based agency with a passion for creating meaningful, emotion-driven digital experiences.`,
    tools: ['React JS', 'Strapi', 'JavaScript'],
  },
  {
    id: 5,
    title: 'Hotpoint Service',
    url: 'https://www.hotpointservice.co.uk/',
    description: `Hotpoint Service is a website where customers can get their products serviced or fixed by using their custom Book a Repair form.`,
    tools: ['Drupal', 'PHP', 'jQuery'],
  },
  {
    id: 6,
    title: 'Twistedfood',
    url: 'https://twistedfood.co.uk/',
    description: `Twisted is a food and recipe platform providing content from around the world. Their content reaches millions of people every day.`,
    tools: ['Next JS', 'Node', 'Redux'],
  },
  {
    id: 7,
    title: 'Jacks of London',
    url: 'https://www.jacksoflondon.co.uk/',
    description: `The original modern barber. Jacks is a Barber franchise taking over London.`,
    tools: ['WordPress', 'PHP', 'jQuery'],
  },
];
