import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container } from 'components/common';
import dev from 'assets/illustrations/lemonade.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Hello!</h1>
          <h2>
            I’m Lewis Vickers. I’m a Software Engineer. Working @{' '}
            <a href="https://www.booking.com/" target="_blank" rel="noopener noreferrer">
              Booking.com
            </a>{' '}
            in Manchester, UK.
          </h2>
        </Details>
        <Thumbnail>
          <img src={dev} alt="Lewis and his dog- Lemonade" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
