import React, { useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';
import { work } from '../../../data/work';

const WorkProjects = () => {
  const { theme } = useContext(ThemeContext);
  return work.map(({ id, title, url, description, tools }) => (
    <Item key={id} as="a" href={url} target="_blank" rel="noopener noreferrer" theme={theme}>
      <Card theme={theme}>
        <Content>
          <h1>{title}</h1>
          <p>{description}</p>
        </Content>
        <TitleWrap>
          <Stats theme={theme}>
            <Languages>
              {tools.map((tool, index) => (
                <span key={index}>{tool}</span>
              ))}
            </Languages>
          </Stats>
        </TitleWrap>
      </Card>
    </Item>
  ));
};

const GitProjects = () => {
  const { theme } = useContext(ThemeContext);
  const {
    github: {
      viewer: {
        pinnedItems: { edges },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            pinnedItems(first: 10) {
              edges {
                node {
                  ... on GitHub_Repository {
                    id
                    name
                    url
                    description
                    stargazers {
                      totalCount
                    }
                    forkCount
                    languages(first: 3) {
                      nodes {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return edges.map(({ node }) => (
    <Item key={node.id} as="a" href={node.url} target="_blank" rel="noopener noreferrer" theme={theme}>
      <Card theme={theme}>
        <Content>
          <h1>{node.name}</h1>
          <p>{node.description}</p>
        </Content>
        <TitleWrap>
          <Stats theme={theme}>
            <div>
              <Star color={theme === 'light' ? '#000' : '#fff'} />
              <span>{node.stargazers.totalCount}</span>
            </div>
            <div>
              <Fork color={theme === 'light' ? '#000' : '#fff'} />
              <span>{node.forkCount}</span>
            </div>
          </Stats>
          <Stats theme={theme}>
            <Languages>
              {node.languages.nodes.map(({ id, name }) => (
                <span key={id}>{name}</span>
              ))}
            </Languages>
          </Stats>
        </TitleWrap>
      </Card>
    </Item>
  ));
};

export const Projects = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <Wrapper as={Container} id="projects">
      <h2 className="toggleWork">
        <button type="button" onClick={() => setToggle(true)} className={toggle ? 'active' : ''}>
          Commercial Work
        </button>
        <span>/</span>
        <button type="button" onClick={() => setToggle(false)} className={!toggle ? 'active' : ''}>
          GitHub
        </button>
      </h2>
      <Grid>{toggle ? <WorkProjects work={work} /> : <GitProjects />}</Grid>
    </Wrapper>
  );
};
